import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { TemplateProject } from '../../templates';
import { ProjectImage, ProjectImageDouble } from '../../components';
export const meta = {
  title: 'ShowHow',
  hint: 'ShowHow — VR e-learning platform',
  order: 112,
  type: 'project',
  published: true,
  seoTitle: 'ShowHow',
  seoDescription: 'ShowHow - UX design for the VR e-learning platform that helps users to create immersive experiences.'
};
export const content = {
  images: {
    hero: {
      src: '/images/showhow/showhow-intro.jpg',
      alt: 'VR E-learning Platform - UI Design | ShowHow',
      density: [1, 2, 4],
      breakpoint: ['s', 'm', 'l']
    },
    bottom: {
      src: '/images/showhow/showhow-end.jpg',
      alt: 'VR Design - ShowHow',
      density: [1, 2, 4],
      breakpoint: ['s', 'm', 'l']
    },
    thumbnail: {
      src: '/images/showhow/showhow-thumb.jpg',
      alt: 'RankSense',
      density: [1, 2, 4]
    }
  },
  tags: ['VR', 'SaaS', 'app', 'responsive', 'mobile', 'learning', 'courses'],
  excerpt: 'ShowHow is a cloud platform that helps users create immersive learning experiences to deliver better training through virtual reality (VR), 360 videos and 3D content.',
  quote: 'showhow-1',
  description: 'ShowHow is a cloud platform that helps users create immersive learning experiences to deliver better training through virtual reality (VR), 360 videos and 3D content. Courses created on the platform focus on practical experiences to prepare users for military operations, medical emergencies and work in hazardous environments.',
  problem: 'Our biggest challenge was to design a course creation workflow. One course can have many scenarios with dozens of sections plus a variety of tests and tasks for users to complete including finding objects in 3D space or in a video. The information architecture for ShowHow was complex, especially with the need to keep the interface consistent across VR, mobile and desktop devices.',
  solution: 'We experimented with various setups using interface wireframes before deciding on an exact user experience. We also worked closely with the development team to ensure that our designs were suitable for each supported device. Finally, we resolved a tricky issue dealing with UI consistency using a curved virtual screen for most of the interface elements in the VR realm.',
  figures: [{
    value: '5',
    label: 'Supported devices'
  }, {
    value: '2',
    label: 'Designed applications'
  }, {
    value: '2',
    label: 'Years of collaboration'
  }, {
    value: '$899',
    label: 'Base monthly subscription cost'
  }]
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  content,
  _frontmatter
};
const MDXLayout = TemplateProject;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe1pSg//xAAYEAACAwAAAAAAAAAAAAAAAAABEBIhQf/aAAgBAQABBQK5hav/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAEBAQAAAAAAAAAAAAAAAAAQASH/2gAIAQEABj8Cxr//xAAcEAEAAQQDAAAAAAAAAAAAAAABEQAQITFBYYH/2gAIAQEAAT8hYYBHeaXc+2DiKht//9oADAMBAAIAAwAAABAv/wD/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAgMAAwAAAAAAAAAAAAABESEAEHExQVH/2gAIAQEAAT8QQ6Tm3OGPH0v20wiQxCNvcu8j01//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/2628aa61e4793d6a92395b911399b851/d2432/showhow-problem.webp 314w", "/static/2628aa61e4793d6a92395b911399b851/41786/showhow-problem.webp 628w", "/static/2628aa61e4793d6a92395b911399b851/d4599/showhow-problem.webp 1256w", "/static/2628aa61e4793d6a92395b911399b851/72bc3/showhow-problem.webp 1884w", "/static/2628aa61e4793d6a92395b911399b851/51747/showhow-problem.webp 2512w", "/static/2628aa61e4793d6a92395b911399b851/4e71a/showhow-problem.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/2628aa61e4793d6a92395b911399b851/7a743/showhow-problem.jpg 314w", "/static/2628aa61e4793d6a92395b911399b851/6f105/showhow-problem.jpg 628w", "/static/2628aa61e4793d6a92395b911399b851/10d53/showhow-problem.jpg 1256w", "/static/2628aa61e4793d6a92395b911399b851/b2e4b/showhow-problem.jpg 1884w", "/static/2628aa61e4793d6a92395b911399b851/fdcb3/showhow-problem.jpg 2512w", "/static/2628aa61e4793d6a92395b911399b851/78bd4/showhow-problem.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/2628aa61e4793d6a92395b911399b851/10d53/showhow-problem.jpg",
            "alt": "User Experience Design Problem - UI Design | ShowHow",
            "title": "User Experience Design Problem - UI Design | ShowHow",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQL/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAG5iXejaJpR/8QAGxAAAQUBAQAAAAAAAAAAAAAAAQACAxESIjH/2gAIAQEAAQUCteLbUJODML20n//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AYj/xAAXEQEAAwAAAAAAAAAAAAAAAAAAARIT/9oACAECAQE/AaSzf//EABgQAQEBAQEAAAAAAAAAAAAAAAABMWER/9oACAEBAAY/AuOtizxlWv/EABwQAQACAgMBAAAAAAAAAAAAAAEAETFBIWFxgf/aAAgBAQABPyHr9XFQ70LzEGkn2FzVu4cuK4vFls//2gAMAwEAAgADAAAAEEPf/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8Qpb//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQBR/9oACAECAQE/EG0DHV3/xAAbEAEBAQEAAwEAAAAAAAAAAAABEQAhMUGx0f/aAAgBAQABPxBDGGG+qaIj2uTj5kwxxHwyg9CB4XKOACU7Z+a7ajEu/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/1f55e8e6ffd4d1e6798ca1a33421944b/d2432/showhow-users.webp 314w", "/static/1f55e8e6ffd4d1e6798ca1a33421944b/41786/showhow-users.webp 628w", "/static/1f55e8e6ffd4d1e6798ca1a33421944b/d4599/showhow-users.webp 1256w", "/static/1f55e8e6ffd4d1e6798ca1a33421944b/72bc3/showhow-users.webp 1884w", "/static/1f55e8e6ffd4d1e6798ca1a33421944b/51747/showhow-users.webp 2512w", "/static/1f55e8e6ffd4d1e6798ca1a33421944b/4e71a/showhow-users.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/1f55e8e6ffd4d1e6798ca1a33421944b/7a743/showhow-users.jpg 314w", "/static/1f55e8e6ffd4d1e6798ca1a33421944b/6f105/showhow-users.jpg 628w", "/static/1f55e8e6ffd4d1e6798ca1a33421944b/10d53/showhow-users.jpg 1256w", "/static/1f55e8e6ffd4d1e6798ca1a33421944b/b2e4b/showhow-users.jpg 1884w", "/static/1f55e8e6ffd4d1e6798ca1a33421944b/fdcb3/showhow-users.jpg 2512w", "/static/1f55e8e6ffd4d1e6798ca1a33421944b/78bd4/showhow-users.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/1f55e8e6ffd4d1e6798ca1a33421944b/10d53/showhow-users.jpg",
            "alt": "Personas - UI Design | ShowHow",
            "title": "Personas - UI Design | ShowHow",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHEKMFkh//EABgQAQEAAwAAAAAAAAAAAAAAAAEAEBES/9oACAEBAAEFAgUc9bm//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAgMAAAAAAAAAAAAAAAAAEBEgISL/2gAIAQEABj8CcNWP/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITFRYXH/2gAIAQEAAT8hpAv5F6qXDGx2K5D8vYC8wn//2gAMAwEAAgADAAAAELQf/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Qh//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/EKf/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUFRcdHw/9oACAEBAAE/EMcU2IU8m5qE16EjlqXBAGxOJffQmfcTYvQtz//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/2be742b8db2045c2e6c7c8dd060cd2dd/d2432/showhow-timeline.webp 314w", "/static/2be742b8db2045c2e6c7c8dd060cd2dd/41786/showhow-timeline.webp 628w", "/static/2be742b8db2045c2e6c7c8dd060cd2dd/d4599/showhow-timeline.webp 1256w", "/static/2be742b8db2045c2e6c7c8dd060cd2dd/72bc3/showhow-timeline.webp 1884w", "/static/2be742b8db2045c2e6c7c8dd060cd2dd/51747/showhow-timeline.webp 2512w", "/static/2be742b8db2045c2e6c7c8dd060cd2dd/4e71a/showhow-timeline.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/2be742b8db2045c2e6c7c8dd060cd2dd/7a743/showhow-timeline.jpg 314w", "/static/2be742b8db2045c2e6c7c8dd060cd2dd/6f105/showhow-timeline.jpg 628w", "/static/2be742b8db2045c2e6c7c8dd060cd2dd/10d53/showhow-timeline.jpg 1256w", "/static/2be742b8db2045c2e6c7c8dd060cd2dd/b2e4b/showhow-timeline.jpg 1884w", "/static/2be742b8db2045c2e6c7c8dd060cd2dd/fdcb3/showhow-timeline.jpg 2512w", "/static/2be742b8db2045c2e6c7c8dd060cd2dd/78bd4/showhow-timeline.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/2be742b8db2045c2e6c7c8dd060cd2dd/10d53/showhow-timeline.jpg",
            "alt": "Workflow and Timeline - UI Design | ShowHow",
            "title": "Workflow and Timeline - UI Design | ShowHow",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHtTSt2A//EABkQAAMBAQEAAAAAAAAAAAAAAAABAgMSIf/aAAgBAQABBQKq5FYjSaYsfUf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAABBAMAAAAAAAAAAAAAAAABABARMRIhYf/aAAgBAQAGPwKiuMcUCRuLb//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExQVFx/9oACAEBAAE/Ie1R6TspfrYlNKglmvrEonE2gQ1tn//aAAwDAQACAAMAAAAQSA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAgEBPxCH/8QAHRABAAICAgMAAAAAAAAAAAAAAQARMUFRYXHR4f/aAAgBAQABPxAg2AW4CLj2At4PsBrvhZhaL0DZ6l6QBiedYYBO6qp//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/5b7c7a2f496e42cfd276cca9b2a05dd0/d2432/showhow-flow.webp 314w", "/static/5b7c7a2f496e42cfd276cca9b2a05dd0/41786/showhow-flow.webp 628w", "/static/5b7c7a2f496e42cfd276cca9b2a05dd0/d4599/showhow-flow.webp 1256w", "/static/5b7c7a2f496e42cfd276cca9b2a05dd0/72bc3/showhow-flow.webp 1884w", "/static/5b7c7a2f496e42cfd276cca9b2a05dd0/51747/showhow-flow.webp 2512w", "/static/5b7c7a2f496e42cfd276cca9b2a05dd0/c31c3/showhow-flow.webp 4000w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/5b7c7a2f496e42cfd276cca9b2a05dd0/7a743/showhow-flow.jpg 314w", "/static/5b7c7a2f496e42cfd276cca9b2a05dd0/6f105/showhow-flow.jpg 628w", "/static/5b7c7a2f496e42cfd276cca9b2a05dd0/10d53/showhow-flow.jpg 1256w", "/static/5b7c7a2f496e42cfd276cca9b2a05dd0/b2e4b/showhow-flow.jpg 1884w", "/static/5b7c7a2f496e42cfd276cca9b2a05dd0/fdcb3/showhow-flow.jpg 2512w", "/static/5b7c7a2f496e42cfd276cca9b2a05dd0/5e632/showhow-flow.jpg 4000w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/5b7c7a2f496e42cfd276cca9b2a05dd0/10d53/showhow-flow.jpg",
            "alt": "User Journey - UI Design | ShowHow",
            "title": "User Journey - UI Design | ShowHow",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwACBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHtJjXUsf/EABsQAAEFAQEAAAAAAAAAAAAAAAABAgMSExEh/9oACAEBAAEFAnO4aJUkh0MPKof/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAQUAAAAAAAAAAAAAAAAAERASITEyof/aAAgBAQAGPwLDKrqNkJ8j/8QAHBABAAICAwEAAAAAAAAAAAAAAQAhEUEQMVHw/9oACAEBAAE/IeiKi1ogtnB7FdSmvjHzMCA8n//aAAwDAQACAAMAAAAQiC//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAgEBPxCn/8QAGxABAQEAAgMAAAAAAAAAAAAAAREAITFhgcH/2gAIAQEAAT8QZCRRQQ7cXQRypQwiCNHkxwTcGSvv5pmPmOsBlgDf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/a5e58411cf5ffacdc75943fc5bf35308/d2432/showhow-hifi-1.webp 314w", "/static/a5e58411cf5ffacdc75943fc5bf35308/41786/showhow-hifi-1.webp 628w", "/static/a5e58411cf5ffacdc75943fc5bf35308/d4599/showhow-hifi-1.webp 1256w", "/static/a5e58411cf5ffacdc75943fc5bf35308/72bc3/showhow-hifi-1.webp 1884w", "/static/a5e58411cf5ffacdc75943fc5bf35308/51747/showhow-hifi-1.webp 2512w", "/static/a5e58411cf5ffacdc75943fc5bf35308/4e71a/showhow-hifi-1.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/a5e58411cf5ffacdc75943fc5bf35308/7a743/showhow-hifi-1.jpg 314w", "/static/a5e58411cf5ffacdc75943fc5bf35308/6f105/showhow-hifi-1.jpg 628w", "/static/a5e58411cf5ffacdc75943fc5bf35308/10d53/showhow-hifi-1.jpg 1256w", "/static/a5e58411cf5ffacdc75943fc5bf35308/b2e4b/showhow-hifi-1.jpg 1884w", "/static/a5e58411cf5ffacdc75943fc5bf35308/fdcb3/showhow-hifi-1.jpg 2512w", "/static/a5e58411cf5ffacdc75943fc5bf35308/78bd4/showhow-hifi-1.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/a5e58411cf5ffacdc75943fc5bf35308/10d53/showhow-hifi-1.jpg",
            "alt": "High Fidelity Prototype - UI Design | ShowHow",
            "title": "High Fidelity Prototype - UI Design | ShowHow",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB2pUq0Qj/xAAaEAEAAQUAAAAAAAAAAAAAAAACERASISIx/9oACAEBAAEFAo2pCuXBk//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAEFAAAAAAAAAAAAAAAAABAAARExUf/aAAgBAQAGPwIvgqF//8QAGxABAQEAAgMAAAAAAAAAAAAAAREAIVFhgZH/2gAIAQEAAT8hYaL9wvdw8YoKdMESl95xa8Fu/9oADAMBAAIAAwAAABC0P//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQACAgMBAAAAAAAAAAAAAAEAESFxMUFRgf/aAAgBAQABPxBLFpwqvhxDZxPssD6S2SlAttY761ncwWQtFHQzGwbqwPs//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/ecd92e5efb9b185a9ed8896b01875487/d2432/showhow-hifi-2.webp 314w", "/static/ecd92e5efb9b185a9ed8896b01875487/41786/showhow-hifi-2.webp 628w", "/static/ecd92e5efb9b185a9ed8896b01875487/d4599/showhow-hifi-2.webp 1256w", "/static/ecd92e5efb9b185a9ed8896b01875487/72bc3/showhow-hifi-2.webp 1884w", "/static/ecd92e5efb9b185a9ed8896b01875487/51747/showhow-hifi-2.webp 2512w", "/static/ecd92e5efb9b185a9ed8896b01875487/4e71a/showhow-hifi-2.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/ecd92e5efb9b185a9ed8896b01875487/7a743/showhow-hifi-2.jpg 314w", "/static/ecd92e5efb9b185a9ed8896b01875487/6f105/showhow-hifi-2.jpg 628w", "/static/ecd92e5efb9b185a9ed8896b01875487/10d53/showhow-hifi-2.jpg 1256w", "/static/ecd92e5efb9b185a9ed8896b01875487/b2e4b/showhow-hifi-2.jpg 1884w", "/static/ecd92e5efb9b185a9ed8896b01875487/fdcb3/showhow-hifi-2.jpg 2512w", "/static/ecd92e5efb9b185a9ed8896b01875487/78bd4/showhow-hifi-2.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/ecd92e5efb9b185a9ed8896b01875487/10d53/showhow-hifi-2.jpg",
            "alt": "VR/AR Design - UI Design | ShowHow",
            "title": "VR/AR Design - UI Design | ShowHow",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAUC/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABsb3OKIP/xAAZEAACAwEAAAAAAAAAAAAAAAABAwACERD/2gAIAQEAAQUCYLmDc491lsn/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAEAAQUAAAAAAAAAAAAAAAABEAAREiEi/9oACAEBAAY/AuEKLu5xAj//xAAaEAABBQEAAAAAAAAAAAAAAAABABARITFh/9oACAEBAAE/IYCc6gAGS4cIKUNb/9oADAMBAAIAAwAAABDD/wD/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEBAAIDAQAAAAAAAAAAAAABEQBBECFhMf/aAAgBAQABPxA+VDZoutY1hAGOl3rhLuYPTtSrW++YfM//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/9c99db91b0ac514b863ad63675f84ab0/d2432/showhow-hifi-3.webp 314w", "/static/9c99db91b0ac514b863ad63675f84ab0/41786/showhow-hifi-3.webp 628w", "/static/9c99db91b0ac514b863ad63675f84ab0/d4599/showhow-hifi-3.webp 1256w", "/static/9c99db91b0ac514b863ad63675f84ab0/72bc3/showhow-hifi-3.webp 1884w", "/static/9c99db91b0ac514b863ad63675f84ab0/51747/showhow-hifi-3.webp 2512w", "/static/9c99db91b0ac514b863ad63675f84ab0/4e71a/showhow-hifi-3.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/9c99db91b0ac514b863ad63675f84ab0/7a743/showhow-hifi-3.jpg 314w", "/static/9c99db91b0ac514b863ad63675f84ab0/6f105/showhow-hifi-3.jpg 628w", "/static/9c99db91b0ac514b863ad63675f84ab0/10d53/showhow-hifi-3.jpg 1256w", "/static/9c99db91b0ac514b863ad63675f84ab0/b2e4b/showhow-hifi-3.jpg 1884w", "/static/9c99db91b0ac514b863ad63675f84ab0/fdcb3/showhow-hifi-3.jpg 2512w", "/static/9c99db91b0ac514b863ad63675f84ab0/78bd4/showhow-hifi-3.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/9c99db91b0ac514b863ad63675f84ab0/10d53/showhow-hifi-3.jpg",
            "alt": "SaaS Application Design - UI Design | ShowHow",
            "title": "SaaS Application Design - UI Design | ShowHow",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAFapJfNihL/xAAaEAEAAgMBAAAAAAAAAAAAAAACAAEDBBES/9oACAEBAAEFAjwuibixHuLxUrZEWye//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BR//EABoQAAIDAQEAAAAAAAAAAAAAAAARAQIxIUH/2gAIAQEABj8Cli9MJtY4zD//xAAaEAACAwEBAAAAAAAAAAAAAAABEQBBcRBh/9oACAEBAAE/IVcWMhp144QKCgYDYclon//aAAwDAQACAAMAAAAQPM//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBH/8QAFhEAAwAAAAAAAAAAAAAAAAAAAAEh/9oACAECAQE/EHB0f//EABoQAQADAAMAAAAAAAAAAAAAAAEAESExUcH/2gAIAQEAAT8QFaCjLmsVmgXjiBFAshJBYgHF2eKS5gU13P/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/44ebe1c6247d2728671bbcce2184ea3a/d2432/showhow-hifi-4.webp 314w", "/static/44ebe1c6247d2728671bbcce2184ea3a/41786/showhow-hifi-4.webp 628w", "/static/44ebe1c6247d2728671bbcce2184ea3a/d4599/showhow-hifi-4.webp 1256w", "/static/44ebe1c6247d2728671bbcce2184ea3a/72bc3/showhow-hifi-4.webp 1884w", "/static/44ebe1c6247d2728671bbcce2184ea3a/51747/showhow-hifi-4.webp 2512w", "/static/44ebe1c6247d2728671bbcce2184ea3a/4e71a/showhow-hifi-4.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/44ebe1c6247d2728671bbcce2184ea3a/7a743/showhow-hifi-4.jpg 314w", "/static/44ebe1c6247d2728671bbcce2184ea3a/6f105/showhow-hifi-4.jpg 628w", "/static/44ebe1c6247d2728671bbcce2184ea3a/10d53/showhow-hifi-4.jpg 1256w", "/static/44ebe1c6247d2728671bbcce2184ea3a/b2e4b/showhow-hifi-4.jpg 1884w", "/static/44ebe1c6247d2728671bbcce2184ea3a/fdcb3/showhow-hifi-4.jpg 2512w", "/static/44ebe1c6247d2728671bbcce2184ea3a/78bd4/showhow-hifi-4.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/44ebe1c6247d2728671bbcce2184ea3a/10d53/showhow-hifi-4.jpg",
            "alt": "Mockup - UI Design | ShowHow",
            "title": "Mockup - UI Design | ShowHow",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGa0m4TDCv/xAAaEAADAQADAAAAAAAAAAAAAAABAgMEABES/9oACAEBAAEFApQNONjYK6+WlYzL7WdWPZ//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAADAQEAAAAAAAAAAAAAAAAAAREQEv/aAAgBAQAGPwJQrIVHMz//xAAaEAACAwEBAAAAAAAAAAAAAAAAAREhMUFR/9oACAEBAAE/IYg1vhVpJeI3ki9qh9hU6W5//9oADAMBAAIAAwAAABDvH//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAECAQE/EKf/xAAeEAEBAAEDBQAAAAAAAAAAAAABEQAxQXEhUWGh4f/aAAgBAQABPxDRD6A+4uXVNj3lHYjZMC8nC9R5MagQUavHbHR2+W5//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/18a03bde0277afcddc041d20e0fe2dc7/d2432/showhow-hifi-5.webp 314w", "/static/18a03bde0277afcddc041d20e0fe2dc7/41786/showhow-hifi-5.webp 628w", "/static/18a03bde0277afcddc041d20e0fe2dc7/d4599/showhow-hifi-5.webp 1256w", "/static/18a03bde0277afcddc041d20e0fe2dc7/72bc3/showhow-hifi-5.webp 1884w", "/static/18a03bde0277afcddc041d20e0fe2dc7/51747/showhow-hifi-5.webp 2512w", "/static/18a03bde0277afcddc041d20e0fe2dc7/4e71a/showhow-hifi-5.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/18a03bde0277afcddc041d20e0fe2dc7/7a743/showhow-hifi-5.jpg 314w", "/static/18a03bde0277afcddc041d20e0fe2dc7/6f105/showhow-hifi-5.jpg 628w", "/static/18a03bde0277afcddc041d20e0fe2dc7/10d53/showhow-hifi-5.jpg 1256w", "/static/18a03bde0277afcddc041d20e0fe2dc7/b2e4b/showhow-hifi-5.jpg 1884w", "/static/18a03bde0277afcddc041d20e0fe2dc7/fdcb3/showhow-hifi-5.jpg 2512w", "/static/18a03bde0277afcddc041d20e0fe2dc7/78bd4/showhow-hifi-5.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/18a03bde0277afcddc041d20e0fe2dc7/10d53/showhow-hifi-5.jpg",
            "alt": "User Experience Design - UI Design | ShowHow",
            "title": "User Experience Design - UI Design | ShowHow",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIFAwT/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAGxmvOUBw//xAAaEAEAAgMBAAAAAAAAAAAAAAACAQMABBIR/9oACAEBAAEFArXwZt5mGZyymURrMLzP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHBABAAEEAwAAAAAAAAAAAAAAAQACEBESIUKR/9oACAEBAAY/AtpSPacJMGPIOw2//8QAGxABAAMBAAMAAAAAAAAAAAAAAQARIVFBYYH/2gAIAQEAAT8ht0Ye5wQs2E2r7L1b7SZlXhuADAJ//9oADAMBAAIAAwAAABAPP//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/EIf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCn/8QAGRABAQEBAQEAAAAAAAAAAAAAAREAITFB/9oACAEBAAE/EIYMhkJXMYKiPIBW8ysSMU8OSDnpFHbyGSI7+RpNNVOsN//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/cd63dff231a2bae0afb5dbcf0e4d395e/d2432/showhow-hifi-6.webp 314w", "/static/cd63dff231a2bae0afb5dbcf0e4d395e/41786/showhow-hifi-6.webp 628w", "/static/cd63dff231a2bae0afb5dbcf0e4d395e/d4599/showhow-hifi-6.webp 1256w", "/static/cd63dff231a2bae0afb5dbcf0e4d395e/72bc3/showhow-hifi-6.webp 1884w", "/static/cd63dff231a2bae0afb5dbcf0e4d395e/51747/showhow-hifi-6.webp 2512w", "/static/cd63dff231a2bae0afb5dbcf0e4d395e/4e71a/showhow-hifi-6.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/cd63dff231a2bae0afb5dbcf0e4d395e/7a743/showhow-hifi-6.jpg 314w", "/static/cd63dff231a2bae0afb5dbcf0e4d395e/6f105/showhow-hifi-6.jpg 628w", "/static/cd63dff231a2bae0afb5dbcf0e4d395e/10d53/showhow-hifi-6.jpg 1256w", "/static/cd63dff231a2bae0afb5dbcf0e4d395e/b2e4b/showhow-hifi-6.jpg 1884w", "/static/cd63dff231a2bae0afb5dbcf0e4d395e/fdcb3/showhow-hifi-6.jpg 2512w", "/static/cd63dff231a2bae0afb5dbcf0e4d395e/78bd4/showhow-hifi-6.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/cd63dff231a2bae0afb5dbcf0e4d395e/10d53/showhow-hifi-6.jpg",
            "alt": "VR/AR Experience Design - UI Design | ShowHow",
            "title": "VR/AR Experience Design - UI Design | ShowHow",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.1592356687898%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB2YHdbaQiv//EABsQAQEAAQUAAAAAAAAAAAAAAAIBAAMEEhMx/9oACAEBAAEFAmuOPc0qeajVgUg7Hn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAWEQADAAAAAAAAAAAAAAAAAAAAARL/2gAIAQIBAT8BlkM//8QAGxAAAgEFAAAAAAAAAAAAAAAAAAEREBIxQWH/2gAIAQEABj8CGoxTorlLNH//xAAaEAADAAMBAAAAAAAAAAAAAAAAAREhMVGR/9oACAEBAAE/IWtqkVHClexqj6jAXBdjoKO/B//aAAwDAQACAAMAAAAQG8//xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQMBAT8QmI//xAAWEQEBAQAAAAAAAAAAAAAAAAABERD/2gAIAQIBAT8QTZM//8QAHBABAQEAAgMBAAAAAAAAAAAAAREAIVExcYHh/9oACAEBAAE/EDAMJynkw+sAa181vIg5QQE5Oo/mNGVWBW+9EgHWf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/37d54e1a66836e3578e4e78c4cad9b86/d2432/showhow-hifi-7.webp 314w", "/static/37d54e1a66836e3578e4e78c4cad9b86/41786/showhow-hifi-7.webp 628w", "/static/37d54e1a66836e3578e4e78c4cad9b86/d4599/showhow-hifi-7.webp 1256w", "/static/37d54e1a66836e3578e4e78c4cad9b86/72bc3/showhow-hifi-7.webp 1884w", "/static/37d54e1a66836e3578e4e78c4cad9b86/51747/showhow-hifi-7.webp 2512w", "/static/37d54e1a66836e3578e4e78c4cad9b86/4e71a/showhow-hifi-7.webp 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/37d54e1a66836e3578e4e78c4cad9b86/7a743/showhow-hifi-7.jpg 314w", "/static/37d54e1a66836e3578e4e78c4cad9b86/6f105/showhow-hifi-7.jpg 628w", "/static/37d54e1a66836e3578e4e78c4cad9b86/10d53/showhow-hifi-7.jpg 1256w", "/static/37d54e1a66836e3578e4e78c4cad9b86/b2e4b/showhow-hifi-7.jpg 1884w", "/static/37d54e1a66836e3578e4e78c4cad9b86/fdcb3/showhow-hifi-7.jpg 2512w", "/static/37d54e1a66836e3578e4e78c4cad9b86/78bd4/showhow-hifi-7.jpg 5600w"],
            "sizes": "(max-width: 1256px) 100vw, 1256px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/37d54e1a66836e3578e4e78c4cad9b86/10d53/showhow-hifi-7.jpg",
            "alt": "E-learning Platform Design - UI Design | ShowHow",
            "title": "E-learning Platform Design - UI Design | ShowHow",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      